.gauge-container {

    & > .gauge-graph {
      border-radius: 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      padding: 4px 4px;
  
      & > .gauge-outer-line {
        display: inline-block;
        //border: 3px solid #F6F9FC;
        padding: 15px;
        border-radius: 100%;
        border-bottom: none;
        width: 180px;
        height: 180px;
        position: relative;
    
        & > .center-point {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -17px;
          margin-left: -17px;
        }
    
        & > .indicator {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -1px;
          margin-left: 0px;
          transform-origin: top left;
          transition: transform 1s;
          // animation: indicatorRotate 2s linear infinite;
    
          &[value="1"] {
            transform: rotate(110deg);
          }

          &[value="2"] {
            transform: rotate(140deg);
          }
    
          &[value="3"] {
            transform: rotate(170deg);
          }

          &[value="4"] {
            transform: rotate(200deg);
          }
    
          &[value="5"] {
            transform: rotate(235deg);
          }

          &[value="6"] {
            transform: rotate(265deg);
          }
    
          &[value="7"] {
            transform: rotate(300deg);
            margin-top: -2px;
          }

          &[value="8"] {
            transform: rotate(330deg);
            margin-top: -2px;
          }
    
          &[value="9"] {
            transform: rotate(345deg);
            margin-top: -2px;
          }

          &[value="10"] {
            transform: rotate(380deg);
            margin-top: -2px;
          }
        }
    
        & > .value-indicators {
          //background: #fff;
          padding: 0 0 10px;
          width: 100%;
          display: flex;
          position: relative;
    
          & > .low-side {
            display: inline-block;
            width: 32px;
            height: 20px;
            border-radius: 6px;
            background: #CFE2F3;
            color: #000;
            padding: 1px 11px;
            font-size: 12px;
            margin: -13px 4px;
          }
          
          & > .high-side {
            position: absolute;
            right: 0;
            display: inline-block;
            width: 36px;
            height: 20px;
            border-radius: 6px;
            background: #9A2335;
            color: #fff;
            padding: 1px 11px;
            font-size: 12px;
            margin: -15px -2px;
          }
        }
    
      }

      & > .value-display {
      position: absolute;
      width: 100%;
      transform: translateY(130px);
      text-align: center;
      font-weight: 700;

      .color12 { color: #000000; }
      .color34 { color: #FFE765; }
      .color56 { color: #FE7754; }
      .color78 { color: #CC394F; }
      .color910 { color: #9A2335; }


      & > .value {
        font-size: 24px;
        line-height: 28px;
      }

      & > .label {
        font-size: 14px;
      }
    }
    }
  }

.gauge {
    height: 150px;
    width: 150px;
  
    & > circle {
      stroke-width: 8px;
      fill: transparent;
    }
  }
  

  #gray{
    stroke: #CFE2F3;
    stroke-dasharray: 41.5, 284; /* length of arc, circumference of circle */
    stroke-dashoffset: -102.75;
    stroke-linecap: round;
  }
  #yellow{
    stroke: #FFE765;
    stroke-dasharray: 44.5, 284; /* length of arc, circumference of circle */
    stroke-dashoffset: -143.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    stroke-linecap: round;
  }
  #orange{
    stroke: #FE7754;
    stroke-dasharray: 51.5, 284; /* length of arc, circumference of circle */
    stroke-dashoffset: -187.75; /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
    stroke-linecap: round;
  }
  #red{
    stroke: #CC394F;
    stroke-dasharray: 44.5, 284; /* length of arc, circumference of circle */
    stroke-dashoffset: -238.75; /* offset of  arc from start point (1/2 of arc length) */
    stroke-linecap: round;
  }
  #darkRed{
    stroke: #9A2335;
    stroke-dasharray: 41.5, 284; /* length of arc, circumference of circle */
    stroke-dashoffset: -323.75; /* offset of arc from start point (1/2 arc length + 3/4 circumference) */
    stroke-linecap: round;
  }