:root {
  // primary
  --primary-50: #E0F8F7;
  --primary-100: #B3ECEC;
  --primary-200: #83E0E1;
  --primary-300: #53D3D7;
  --primary-400: #31C9CF;
  --primary-500: #12AFB8; // primary
  --primary-600: #139A9F;
  --primary-700: #128688;
  --primary-800: #10635F;
  //  secondary
  --secondary-50: #E2F6FA;
  --secondary-100: #B6E7F2;
  --secondary-200: #89D7EA;
  --secondary-300: #62C7E3;
  --secondary-400: #48BADF;
  --secondary-500: #34AFDC;
  --secondary-600: #2CA1CE;
  --secondary-700: #228EBC; // secondary
  --secondary-800: #207DA8;
  --secondary-900: #155D87;
  //  tertiary
  --tertiary-50: #FFF3E0;
  --tertiary-100: #FFDFB2;
  --tertiary-200: #FFCA81;
  --tertiary-300: #FEB54F;
  --tertiary-400: #FEA42B;
  --tertiary-500: #FA890E;
  --tertiary-600: #FE7754; //tertiary
  --tertiary-700: #F37A0D;
  --tertiary-800: #ED6A0C;
  --tertiary-900: #E34F0B;
  //other
  --success-green: #3ADA7A;
  --functional-red: #F65C5C;
  --functional-purple: #C745F4;
  --headings: rgba(0, 0, 0, 0.87);
  --body: rgba(0, 0, 0, 0.6);
  --subtitle: rgba(0, 0, 0, 0.3);
  --disabled: var(--subtitle);
  --shadow: rgba(0, 0, 0, 0.14);
  --background: #F6F9FC;
  --white: #FFFFFF;
  --black: #000000;
  --grey1: #C4C4C4;
  --grey2: #DADADA;
  --surface-overlay: rgba(33, 33, 33, 0.08);
  --critical-severity: #9a2335;
  --high-severity: #cc394f;
  --medium-severity: #fe7754;
  --low-severity: #ffe765;
  --info-severity: #cfe2f3;
  --functional-red-900:#9A2335;
  --functional-red-700:#CC394F;
  --functional-orange:#FE7754;
}
